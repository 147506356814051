$secondaryColor: #071826;
$mainColor: #F28444;;
$borderColor: #004f72;

// $regularFontFamily: Helvetica;
$regularFontFamily: Roboto;

$regularFontSize: 14px;
$bigFontSize: 18px;
$headingFontSize: 25px;
$heading2FontSize: 20px;
