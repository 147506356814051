.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 90px;
  // background-color: #23bbdf;
  background-color: #6BA7BF;

  p {
    font-size: 44px;
    font-family: $regularFontFamily;
    font-weight: bold;
    margin: 30px 0;
  }

  .navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
      margin-left: 20px;
      color: white;
      font-size: 16px;
      font-family: $regularFontFamily;
      font-weight: bold;
      letter-spacing: 1px;
    }

    a:hover {
      cursor: pointer;
      opacity: 0.60;
      color: black;
    }

    button {
      border: none;
      width: 100px;
      background-color: transparent;
      color: $mainColor;
      font-size: $bigFontSize;
      font-family: $regularFontFamily;
      font-weight: bold;
      outline: none;
      cursor: pointer;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media ( max-width: 870px ) {
      padding-top: 20px;
    }

    h2 {
      color: white;
      font-size: 40px;
      letter-spacing: 2px;
    }
    h3 {
      color: white;
      font-size: 25px;
    }
  }

  .logo-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    img {
      margin-right: 20px;
    }

    h2 {
      color: white;
      font-size: 40px;
      letter-spacing: 2px;
    }
    h3 {
      color: white;
      font-size: 25px;
    }
  }
}

@media (max-width: 870px) {
  .header-content {
    flex-direction: column;
    height: 150px;
  }
}

@media (max-width: 660px) {
  .header-content {
    flex-direction: column;
    height: auto;
  }

  .navigation-container {
    flex-direction: column;
    align-items: center;

    a.menu-item {
      cursor: pointer;
      border-right: none;
      border-bottom: 1px solid $borderColor;
      margin-right: 0;
      margin-bottom: 20px;
      padding: 0 10px;
      letter-spacing: 1px;
    }

    a.menu-item:hover {
      cursor: pointer;
    }
  }
}

.header-wrapper, .footer-wrapper {
  padding-top: 5px;
  // background-color: #23bbdf;
    // background-color: lightgrey;
  min-height: 90px;
  background-color: #6BA7BF;
}

.mobile-menu {

  span {
    font-size: 40px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding-right: 10px;
  }

.overlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  margin-right: 20px;
  color: #23bbdf;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 0;
  font-size: 60px;
  color: white;
  cursor: pointer;
}

@media screen and (max-height: 450px) {
  .overlay {overflow-y: auto;}
  .overlay a {font-size: 20px}
  .overlay .closebtn {
  cursor: pointer;
  font-size: 40px;
  top: 15px;
  right: 35px;
  }
}
}

.footer-wrapper{
  display: flex;

  .footer-content {
    // width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;

  }

  h3 {
    color: white;
    font-size: 30px;
    padding-right: 20px;
  }
  a {
    color: white;
    padding-right: 20px;
    font-size: 12px;
  }
}

a {
  text-decoration: none;
  color: #23bbdf;
}


.homepage {

  // background-image: url('/images/5.jpg');

  .cta-wrapper {
    text-align: center;
  }

  a {
    color: white;
  }
}

.cookie-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 20px 20px 20px 20px;
  color: white;
  text-align: center;
  z-index: 2;
  background: rgba(0, 0, 0, 0.75);

  button {
    border: 1px solid #F28444;
    border-radius: 2px;
    background: white;
    color: #F28444;
    font-size: 16px;
    padding: 10px 35px 10px 35px;
  }
  .link {
    font-weight: bold;
  }
}
