@import "../utils/mixin.scss";

.expiring-container {
    padding-top: 10px;
    padding-bottom: 10px;

    h1 {
        text-align: center;
    }
}

.expiring-table-type {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    img {
        // margin-right: 10px;
    }
}

.car-list-actions {
    cursor: pointer;
    
    img {
        margin: 3px 10px 3px 10px;
    }
}

h1.home {
  color: #004f72;
}

h2.home {
  color: #004f72;
}
.reasons {
    list-style: none;

    li:before {
        content: "> ";
        font-size: 18px;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        color: $mainColor;
    }
    li {
        // list-style-type: square;
        color: $borderColor;
        line-height: 1.8;
        font-size: 18px;
    }
}

.slide-container {
    // width: 70%;
    margin: auto; }
  
  h3 {
    text-align: center; }
  
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 300px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  
  .each-fade {
    display: flex;
  }
  
  .each-fade .image-container {
    // width: 75%;
    overflow: hidden;
  }
  
  .each-fade .image-container img {
    width: 100%;
  }
  
  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .mobile-expiring-container {
    color: $borderColor;
    background: whitesmoke;
    margin: 5px 10px 10px 10px;
    padding-top: 10px;
    // border: 1px solid grey;

    div {
      // border-bottom: 1px solid white;
      text-align: center;
      padding: 5px;
    }
  }

  .mobile-car-container {
    color: $borderColor;
    background: whitesmoke;
    margin: 5px 10px 20px 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    img {
      padding-right: 5px;
    }

    .left, .right {
      width: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

  .facebook-login-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .or {
      margin-top: 20px;
    }
  }

.date-wrapper {
    width: 70px;
    height: 50px;
    color: #004f72;
    margin: 5px;

    .header {
      height: 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #6BA7BF;
      border: 1px solid #476471;
    }

    .content {
      border: 1px solid #004f72;
      padding: 10px;
      font-size: 15px;
      text-align: center;
      border-top: none;
    }
}

.alert-type-wrapper {
  color: #004f72;
  padding: 10px;
  font-size: 18px;
  margin: 5px;

  .header {
    text-align: center;
  }

  .content {
    text-align: center;
  }
}

.alerts {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
