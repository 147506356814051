@import "../utils/mixin.scss";

body {
  color: #1a1a1a;
  font-family: 'Roboto', sans-serif;
  background: #EFFBFF;
}

.container {
  max-width: 1200px;
  min-height: 400px;
  margin: 0 auto;
  padding: 0 10px;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  background-color: transparent;

  .text-fields-container {
    @include input-container;

    .text-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        width: 150px;
        font-weight: bold;
      }

      label {
        width: 150px;
        font-weight: bold;
      }
      input {
        width: 180px;
        font-weight: bold;
        height: 30px;
        padding-left: 5px;
        border: none;
        border-bottom: 1px solid lightgray;
      }
    }

    .input-wrapper {
      .field-error{
        color: red;
        text-align: right;
        font-size: 14px;
        padding-bottom: 3px;
        min-height: 20px;
      }
    }

    h1 {
      margin: 0 0 40px 0;
      color: $secondaryColor;
      font-size: $headingFontSize;
      font-family: $regularFontFamily;
    }
  }

  .date-field-container {
    @include input-container;
    // margin-top: 40px;
    // margin-right: 20%;

    .date-input {
      display: flex;
      align-items: flex-end;
      margin-bottom: 30px;

      span {
        width: 150px;
        font-weight: bold;
      }
    }

    h2 {
      margin: 0 0 40px 0;
      color: $secondaryColor;
      font-size: $heading2FontSize;
      font-family: $regularFontFamily;
    }
  }
}

.add-car-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 10px
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.btn-add-car {
  padding: 10px 15px;
  background-color: $mainColor;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  max-height: 50px;
  margin: 10px;

  &:hover {
    cursor: pointer;
  }

  &.delete {
    background-color: tomato;
  }
}