@mixin input-container {
  display: flex;
  flex-direction: column;
  color: $secondaryColor;
  font-size: $regularFontSize;
  font-family: $regularFontFamily;
}

@mixin regular-text {
  font-size: $regularFontSize;
  font-family: $regularFontFamily;
  color: $secondaryColor;
}
