* {
  box-sizing: border-box;
}

body {
  margin: 0;
  // background: rgb(0, 213, 255);
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 213, 255, 1) 0%,
  //   rgba(114, 234, 255, 1) 27%,
  //   rgba(197, 245, 255, 1) 100%
  // );
}
